import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import detail from './detail';
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "recordRepresentation",
  mixins: [mixins],
  components: {
    detail
  },
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      searchItems: [{
        prop: 'phone',
        label: '手机号码'
      }, {
        prop: 'status',
        label: '处理状态',
        type: 'select',
        items: ['处理中', '已驳回', '已处理']
      }, {
        prop: 'representationType',
        label: '申述类型',
        type: 'select',
        items: [],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }],
      columns: [{
        label: '用户',
        prop: 'customerName'
      }, {
        label: '电话',
        prop: 'customerPhone'
      }, {
        label: '申述类型',
        prop: 'recordRepresentationType'
      }, {
        label: '处理状态',
        customRender: props => {
          if (props.row.status === '处理中') {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [props.row.status]
            });
          } else if (props.row.status === '已驳回') {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "danger"
            }, {
              default: () => [props.row.status]
            });
          } else if (props.row.status === '已处理') {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "success"
            }, {
              default: () => [props.row.status]
            });
          } else {
            return props.row.status;
          }
        }
      }, {
        label: '申述时间',
        prop: 'createTime',
        width: '170px'
      }, {
        label: '骑行记录时间',
        prop: 'rideRecordTime',
        width: '170px'
      }],
      topButtons: [{
        name: '导出',
        code: 'recordRepresentation_export',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['recordRepresentation_export']
      }, {
        name: '修改费用导出',
        code: 'refund_export',
        type: 'primary',
        click: this.refundExport,
        permissions: ['refund_export']
      }],
      linkButtons: [{
        name: '详情',
        code: 'recordRepresentation_detail',
        click: this.Detail,
        permissions: ['recordRepresentation_detail']
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.recordRepresentation.exportInfo(search).then(res => {
        this.blobFile(res, '订单申诉.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    refundExport() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.recordRepresentation.refundExport(search).then(res => {
        this.blobFile(res, '修改费用导出.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    Detail(row) {
      this.currentId = row.id;
      this.detailDrawer = true;
    },
    auditComplete() {
      this.detailDrawer = false;
      this.$refs.tablePage.fetch();
    }
  },
  beforeMount() {
    this.$api.business.dictionary.dictItems({
      categoryCode: 'representation_type'
    }).then(res => {
      if (res.code === 200) {
        this.searchItems[2].items = res.data;
      }
    });
  }
};